import Repository from "@/repositories/api.js"
const resource = "/events"

export default {
  async getAll(mode = null) {
    let response = await Repository.get(resource, {
      params: { mode: mode }
    })
    return response.data.events
  },
  async get(eventID) {
    let response = await Repository.get(`${resource}/${eventID}`)
    return response.data.event
  },
  async create(eventData) {
    let response = await Repository.post(`${resource}`, {
      event: eventData
    })
    return response.data
  },
  async update(eventID, eventData) {
    let response = await Repository.patch(`${resource}/${eventID}`, {
      event: eventData
    })
    return response.data
  },
  async destroy(eventID) {
    let response = await Repository.delete(`${resource}/${eventID}`)
    return response.data
  }
}
