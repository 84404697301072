import store from "@/store"
import Repository from "@/repositories/api.js"
const resource = "/admin/events"

export default {
  async getAll(mode = null, organizationID = null) {
    let response = await Repository.get(resource, {
      params: {
        region_id: store.state.auth.chosenRegion,
        mode: mode,
        organization_id: organizationID
      }
    })
    return response.data.events
  },
  async get(eventID) {
    let response = await Repository.get(`${resource}/${eventID}`)
    return response.data.event
  },
  async create(eventData) {
    let response = await Repository.post(`${resource}`, {
      event: eventData
    })
    return response.data
  },
  async update(eventID, eventData) {
    let response = await Repository.patch(`${resource}/${eventID}`, {
      event: eventData
    })
    return response.data
  },
  async destroy(eventID) {
    let response = await Repository.delete(`${resource}/${eventID}`)
    return response.data
  },
  async approve(eventID) {
    let response = await Repository.patch(`${resource}/${eventID}/approve`)
    return response.data
  },
  async reject(eventID, reason, notifySelf) {
    let response = await Repository.patch(`${resource}/${eventID}/reject`, {
      reason: reason,
      notify_self: notifySelf
    })
    return response.data
  },
  async askForEdit(eventID, reason, notifySelf) {
    let response = await Repository.patch(
      `${resource}/${eventID}/ask_for_edit`,
      { reason: reason, notify_self: notifySelf }
    )
    return response.data
  }
}
